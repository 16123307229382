import SoundIcon from './../assets/icons/soundIcon';

export default function SoundButton({audioFile}) {

    const audio = new Audio(audioFile);

    if (!audioFile) return null

    return (
        <div
            className="input__button"
            onClick={() => audio.play()}
        >
            <SoundIcon />
        </div>
    )
}