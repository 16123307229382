export default function RadioIcon() {
    return (
        <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M35.8726 18.4361L18.4365 1L1.00043 18.4361L18.4365 35.8722L35.8726 18.4361Z" stroke="#6D6C6C" strokeMiterlimit="10" />
        </svg>
    )
}