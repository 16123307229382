import VoiceChooser from "../components/voiceChooser/voiceChooser";
import { useDispatch } from 'react-redux';
import { changeMode } from "../redux/appSlice";
import DropdownButton from "../components/dropdownButton";
import { TRANSLATOR_MODE, TABLE_MODE } from './../constants';

export default function Header() {

    const dispatch = useDispatch();

    const dropDownItems = [{
        text: "Переводчик",
        func: () => dispatch(changeMode(TRANSLATOR_MODE))
    }, {
        text: "Таблица",
        func: () => dispatch(changeMode(TABLE_MODE))
    }]

    return (
        <header className="header">
            <div className="header__top">
                <div className="header__logo">
                    <img src="/assets/images/Logo.png" alt="Logo" />
                </div>
                <div className="header__dropdown desctop">
                    <DropdownButton itemList={dropDownItems} />
                </div>
            </div>
            <div className="header__bottom">
                <div className="header__subtitle text text--sm desctop">
                    Онлайн переводчик мансийского языка
                </div>
                <div className="header__dropdown only-mob">
                    <DropdownButton itemList={dropDownItems} />
                </div>
                <div className="header__btns">
                    <VoiceChooser />
                </div>

            </div>
        </header>
    )
}