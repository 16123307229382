import MansiKeys from "./mansiKeys"
import {SHORT_RUS, WORD_NOT_FINDED_ERROR} from './../constants';
import SoundButton from './soundButton';
import {useSelector} from 'react-redux';

export default function TranslationOutput({translations, example, choosedLang, audioFile}) {

    const voice = useSelector(state => state.app.voice);

    const renderSoundButton = () => {
        if (audioFile && audioFile.length > 0 && choosedLang === SHORT_RUS) {
            const rightSound = audioFile.find(item => item.voice_type === voice)
            return <SoundButton audioFile={rightSound?.file} />
        }
        return null
    }

    return (
        <div className="output">
            <div className="output__hidden mobile"><MansiKeys choosedLang={choosedLang} /></div>

            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div className="output__translations text text--mdl">
                    {translations.map(word => {
                        const newWord = word.value[0].toUpperCase() + word.value.slice(1)
                        return <div key={newWord} className="output__translation"> {newWord} </div>
                    })}
                </div>
                {renderSoundButton()}
            </div>

            {translations[0].value !== WORD_NOT_FINDED_ERROR ?
                <>
                    {example ? <div className="output__examples examples">
                        <div className="examples__head text text--mdl">Примеры использования:</div>
                        <div className="output__explanation text text--mdl text--it">{example.value}</div>
                    </div> : null}
                </>
                : null}
        </div>
    )
}