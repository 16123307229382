import VoiceButton from "./voiceButton";
import {useSelector, useDispatch} from 'react-redux';
import {changeVoice} from "../../redux/appSlice";

export default function VoiceChooser() {

    const voice = useSelector(state => state.app.voice);
    const dispatch = useDispatch();

    const setVoice = (text) => dispatch(changeVoice(text))

    return (
        <div className="voice-chooser">
            <span className="voice-chooser__title text text--sm desctop">голос озвучки:</span>
            <div className="voice-chooser__buttons noselect">
                <VoiceButton
                    data={"hum"}
                    text={"Хум"}
                    choosedVoice={voice}
                    changeVoice={setVoice} />
                <VoiceButton
                    data={"ne"}
                    text={"Нэ̄"}
                    choosedVoice={voice}
                    changeVoice={setVoice} />
            </div>

        </div>
    )
}