import PDF from './assets/pdf/confidential.pdf'
import PDFViewer from './pages/PDFViewer';
import Main from './pages/Main';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/confidential.pdf" element={<PDFViewer PDF={PDF} />} />
      </Routes>
    </div>
  );
}

export default App;
