import RadioIconChecked from './../../assets/icons/radioIconChecked';
import RadioIcon from './../../assets/icons/radioIcon';

export default function VoiceButton({data, choosedVoice, text, changeVoice}) {

    return (
        <div className="voice-chooser__button"
            onClick={() => changeVoice(data)}>
            {choosedVoice === data ? <RadioIconChecked /> : <RadioIcon />}
            <span className="voice-chooser__lang text text--sm">{text}</span>
        </div>
    )
}