import Table from "../components/table/table"
import Translator from "../components/translator"
import Layout from "../layout/layout"
import { useSelector } from 'react-redux';
import { TRANSLATOR_MODE } from "../constants";

export default function Main() {

    const appMode = useSelector(state => state.app.mode)

    return <Layout>
        {appMode === TRANSLATOR_MODE ?
            <Translator /> :
            <Table />}
    </Layout>
}