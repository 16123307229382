import ChangeLangIcon from '../assets/icons/changeLangIcon';
import { SHORT_MANS, MANS, RUS, SHORT_RUS } from '../constants';

export default function LangSwaper({ choosedLang, setLang, reset }) {

    const swapLang = () => {
        choosedLang === SHORT_MANS ?
            setLang(SHORT_RUS) :
            setLang(SHORT_MANS);
        reset();
    }

    return (
        <div className="lang-swapper">
            <span className="lang-swapper__lang text text--sm">
                {choosedLang === SHORT_MANS ? MANS : RUS}
            </span>
            <div
                className="lang-swapper__button noselect"
                onClick={swapLang}
            >
                <ChangeLangIcon />
            </div>
            <span className="lang-swapper__lang text text--sm">
                {choosedLang === SHORT_MANS ? RUS : MANS}
            </span>
        </div>
    )
}