import {useEffect} from 'react';
import {useInView} from 'react-intersection-observer';
import {setFirstSymbolCase} from './../features/help';

export default function AutoComplite({searchWord, words, setWord, getTranslation, selectedAutoWord}) {

    const {ref, inView, entry} = useInView({
        threshold: 0,
    });

    useEffect(() => {
        if (!inView && entry) {
            entry.target.scrollIntoView({block: "end"})
        }
    }, [entry, inView])

    return (
        <div className="autocomplite">
            {searchWord.length > 0 && words.map((word, idx) => <div
                key={idx}
                className="autocomplite__word text text--mdl"
                style={{opacity: selectedAutoWord === idx ? 0.4 : 1}}
                ref={selectedAutoWord === idx ? ref : null}
                onClick={() => {
                    setWord(setFirstSymbolCase(word.value, searchWord));
                    getTranslation(word.id);
                }}
            >
                {setFirstSymbolCase(word.value, searchWord)}
            </div>)}
        </div>
    )
}