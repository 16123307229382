import { useState, useRef } from 'react';
import useOutsideClickListener from './../features/hooks/useOutsideClickListener';

export default function DropdownButton({ itemList }) {
    const [title, setTitle] = useState(itemList[0].text);
    const [listIsOpen, setListOpen] = useState(false);
    const wrapRef = useRef(null);

    useOutsideClickListener(wrapRef, () => setListOpen(false))

    const itemClickHandler = (text, func) => {
        setTitle(text);
        func();
        setListOpen(false);
    }

    const headerClickHandler = () => {
        setListOpen(state => !state)
    }

    return (
        <div className="dd__wrapper" ref={wrapRef} >
            <div className={`dd__header ${listIsOpen ? "active" : ""}`} onClick={headerClickHandler}>
                <div className="dd__title text text--sm" >{title}</div>
            </div>

            <div className={`dd__list ${listIsOpen ? "active" : ""}`}>
                {itemList.map(({ text, func }, i) => <div
                    key={i}
                    className={`dd__listItem text text--sm ${text === title ? "active" : ""}`}
                    onClick={() => itemClickHandler(text, func)}
                >
                    {text}
                </div>
                )}
            </div>


        </div>
    )
}