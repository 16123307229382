import LangSwaper from "./langSwaper";
import {useState, useRef} from 'react';
import {SHORT_MANS, WORD_NOT_FINDED_ERROR} from './../constants';
import MansiKeys from "./mansiKeys";
import TranslationInput from "./translationInput";
import {getMansiWord, searchMansiWords, searchRussianWords} from './../api/dataService';
import TranslationOutput from "./translationOutput";


export default function Translator() {

    const [choosedLang, setLang] = useState(SHORT_MANS);
    const [wordForTranslate, setWord] = useState("");
    const [autocompliteWords, setAutocompliteWords] = useState([]);
    const [translation, setTranslation] = useState(null);

    const inputRef = useRef(null);

    const getMansiWords = async (word) => {
        setWord(word)
        if (word.length > 2) {
            const list = (choosedLang === SHORT_MANS) ?
                await searchMansiWords(word.trim()) :
                await searchRussianWords(word.trim());
            setAutocompliteWords(list)
        }
        if (word.length < 2) {
            setAutocompliteWords([])
        }
        setTranslation(null)
    }

    const reset = () => {
        setWord("");
        setAutocompliteWords([]);
        setTranslation(null);
    }

    const getTranslation = async (id) => {
        setAutocompliteWords([])
        if (wordForTranslate.length > 2 && id) {
            const translationInfo = await getMansiWord(id);
            setTranslation(translationInfo);
            return;
        }
        if (!id) {
            setTranslation({
                value: WORD_NOT_FINDED_ERROR,
                rus_words: [{value: WORD_NOT_FINDED_ERROR, }]
            })
        }

    }

    return (
        <div className="translator">
            <div className="translator__header">
                <LangSwaper
                    choosedLang={choosedLang}
                    setLang={setLang}
                    setWord={setWord}
                    reset={reset}
                />
            </div>
            <div className="translator__content">
                <div className="translator__box">
                    <div className="translator__keys">
                        <MansiKeys
                            inputRef={inputRef}
                            choosedLang={choosedLang}
                            word={wordForTranslate}
                            setWord={setWord}
                            setTranslation={setTranslation}
                            getTranslation={getMansiWords}
                        />
                    </div>

                    <TranslationInput
                        reset={reset}
                        inputRef={inputRef}
                        word={wordForTranslate}
                        setWord={setWord}
                        changeHandler={getMansiWords}
                        autocomplite={autocompliteWords}
                        translation={translation}
                        getTranslation={getTranslation}
                        choosedLang={choosedLang}
                    />
                    {
                        translation && translation?.video?.file &&
                        <div className="translator__eximage" style={{backgroundImage: `url(${translation?.video?.file})`}}>
                        </div>
                    }

                </div>
                <div className="translator__box">
                    {translation && <TranslationOutput
                        choosedLang={choosedLang}
                        translations={choosedLang === SHORT_MANS ?
                            translation?.rus_words :
                            [{value: translation?.value}]
                        }
                        example={translation?.example}
                        audioFile={translation?.sound}
                    />}
                </div>
            </div>

        </div>
    )
}