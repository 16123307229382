import { api } from "./apiConfig"

export const searchMansiWords = async (text) => {
    const { data } = await api.get(`v1/mansi_words/?search=${text.toLowerCase()}`);
    return data.results.filter(item => !item.rus_words.some(elem => elem.value.includes(text.toLowerCase())));
}

export const searchMansiAndRusWords = async (text) => {
    const { data } = await api.get(`v1/mansi_words/?search=${text.toLowerCase()}`);
    return data.results
}

export const getMansiWord = async (id) => {
    const { data } = await api.get(`v1/mansi_words/${id}`);
    return data;
}

function unDuplicateArraySingleValues(array) {
    if ((Array.isArray(array) || array instanceof Array)
        && array.length) {
        return array.filter((currValue, ind) => {
            return array.indexOf(currValue) === ind;
        });
    } else {
        return array;
    }
}

const unDuplicateArrayObjects = (array, propertyName) => {
    if ((Array.isArray(array) || array instanceof Array)
        && array.length
        && typeof propertyName === 'string'
        && propertyName.length) {
        const objectValuesArrayFromKey = array.map(item => item[propertyName]);
        const uniqueValues = unDuplicateArraySingleValues(objectValuesArrayFromKey);
        return uniqueValues.map(
            key => array.find(
                item => item[propertyName] === key
            )
        );
    } else {
        return array;
    }
}

export const searchRussianWords = async (text) => {
    const { data } = await api.get(`v1/mansi_words/?search=${text.toLowerCase()}`);
    let arr = [];
    data.results.forEach(result => {
        result.rus_words.forEach(item => arr.push({ id: result.id, value: item.value, mansi_words: [result.value] }))
    })
    arr = unDuplicateArrayObjects(arr, "value")
    return arr

}

export const getAllMansiWords = async (pageNum) => {
    const { data } = await api.get(`v1/mansi_words/?page=${pageNum}&search=`);
    return data
}
