import { useState, useEffect, useRef } from 'react';
import { getAllMansiWords, searchMansiAndRusWords } from '../../api/dataService';
import MansiKeys from '../mansiKeys';
import { SHORT_MANS } from './../../constants';

export default function Table() {

    const [words, setWords] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [page, setPage] = useState(1);
    const [endOfList, setEndOfList] = useState(1);
    const [searchWord, setSearchWord] = useState('');

    const inputRef = useRef(null);

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);
        return function () { document.removeEventListener('scroll', scrollHandler) };
    }, [])

    useEffect(() => {
        if (searchWord.length === 0) {
            reset()
        } else {
            setFetching(false)
            searchMansiAndRusWords(searchWord).then(res => setWords(res));
        }
    }, [searchWord])




    useEffect(() => {
        if (fetching && words.length < endOfList && !searchWord) {
            getAllMansiWords(page)
                .then(res => {
                    setWords([...words, ...res.results]);
                    setEndOfList(res.count);
                    if (res.next) {
                        setPage(prev => prev + 1);
                    }
                })
                .finally(() => setFetching(false))
        }
    }, [fetching])

    const scrollHandler = (e) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) {
            setFetching(true)
        }
    }

    const reset = () => {
        setWords([]);
        setPage(1);
        setEndOfList(1);
        setFetching(true);
    }

    const inputChangeHandler = (e) => {
        setSearchWord(e.target.value);
    }

    return (
        <div className='table__wrapper'>
            <div className='table__header'>
                <input
                    type="text"
                    value={searchWord}
                    onChange={inputChangeHandler}
                    ref={inputRef}
                    placeholder={"Введите слово"}
                    className='text text--sm'
                />
                <MansiKeys
                    word={searchWord}
                    setWord={setSearchWord}
                    choosedLang={SHORT_MANS}
                    inputRef={inputRef}
                />
            </div>
            <div className='table__list'>
                {
                    words.map((word, i) => <div
                        key={i}
                        className='table__item text text--mdl'
                    >
                        <div className='table__translation'>{word.value}</div>
                        <div className='table__translation'>
                            {word.rus_words.map((word, i, arr) => <span
                                key={word.id}
                                className='table__word'>
                                {word.value}{i !== arr.length - 1 ? "," : ""}
                            </span>)}
                        </div>
                    </div>)
                }
            </div>

        </div>
    )
}