import {createSlice} from '@reduxjs/toolkit'
import {TRANSLATOR_MODE} from '../constants'

const initialState = {
    voice: "hum",
    mode: TRANSLATOR_MODE,
}

export const appSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        changeVoice: (state, action) => {
            state.voice = action.payload
        },
        changeMode: (state, action) => {
            state.mode = action.payload
        }
    },
})

export const {changeVoice, changeMode} = appSlice.actions

export default appSlice.reducer