export default function ChangeLangIcon() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0246 8.00867L22.378 7.65493L22.7317 8.0083L22.3784 8.36204L22.0246 8.00867ZM7.01058 8.51659C6.73444 8.51674 6.51046 8.293 6.51031 8.01686C6.51017 7.74072 6.73391 7.51674 7.01005 7.51659L7.01058 8.51659ZM17.3706 2.65281L22.378 7.65493L21.6712 8.36241L16.6638 3.36029L17.3706 2.65281ZM22.3784 8.36204L17.3762 13.3694L16.6687 12.6627L21.6709 7.6553L22.3784 8.36204ZM22.0249 8.50867L7.01058 8.51659L7.01005 7.51659L22.0244 7.50867L22.0249 8.50867Z" fill="black" fillOpacity="0.6" />
            <path d="M2.00997 16.0267L1.65623 15.6734L1.30286 16.0271L1.6566 16.3805L2.00997 16.0267ZM17.0245 16.5188C17.3007 16.5187 17.5244 16.2947 17.5243 16.0186C17.5241 15.7424 17.3001 15.5187 17.024 15.5188L17.0245 16.5188ZM6.65835 10.666L1.65623 15.6734L2.36371 16.3801L7.36583 11.3727L6.65835 10.666ZM1.6566 16.3805L6.66401 21.3826L7.37074 20.6751L2.36333 15.673L1.6566 16.3805ZM2.01023 16.5267L17.0245 16.5188L17.024 15.5188L2.0097 15.5267L2.01023 16.5267Z" fill="black" fillOpacity="0.6" />
        </svg>

    )
}