export default function SoundIcon() {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M3.4219 15.0915C2.64948 13.8041 2.64948 12.1959 3.4219 10.9085V10.9085C3.65761 10.5156 4.04811 10.2404 4.49735 10.1506L6.33146 9.78377C6.44073 9.76192 6.53923 9.70331 6.61057 9.61771L9.08784 6.64498C10.2704 5.22592 10.8617 4.51638 11.3893 4.70742C11.917 4.89846 11.917 5.82207 11.917 7.66928L11.917 18.3307C11.917 20.1779 11.917 21.1015 11.3893 21.2926C10.8617 21.4836 10.2704 20.7741 9.08784 19.355L6.61057 16.3823C6.53923 16.2967 6.44073 16.2381 6.33146 16.2162L4.49735 15.8494C4.04811 15.7596 3.65761 15.4844 3.4219 15.0915V15.0915Z" stroke="#6D6C6C" />
            <path d="M16.8298 9.17C17.8404 10.1806 18.4107 11.5496 18.4163 12.9788C18.4219 14.408 17.8625 15.7815 16.8599 16.8" stroke="#6D6C6C" strokeLinecap="round" />
            <path d="M21.2949 6.8719C22.9119 8.48885 23.8243 10.6793 23.8333 12.966C23.8423 15.2527 22.9472 17.4503 21.343 19.0799" stroke="#6D6C6C" strokeLinecap="round" />
        </svg>

    )
}