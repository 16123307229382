import { SHORT_MANS } from './../constants';

export default function MansiKeys({
    word,
    setWord,
    choosedLang,
    setTranslation = () => false,
    inputRef,
    getTranslation = () => false,
}) {

    const letters = ['ā', 'ē', 'ё̄', 'ӣ', 'ӈ', 'о̄', 'ӯ', 'ы̄', 'э̄', 'ю̄', 'я̄'];

    const pressHandler = (letter) => {
        const cursorPosition = inputRef?.current?.selectionStart;
        let newWord = word.split('');
        newWord.splice(cursorPosition, 0, letter);
        setWord(newWord.join(''));
        setTranslation(null);
        getTranslation(newWord.join(''))
        setTimeout(() => {
            inputRef?.current?.focus();
            inputRef?.current?.setSelectionRange(cursorPosition + letter.length, cursorPosition + letter.length)
        }, 0)
    }

    return (<>
        {choosedLang === SHORT_MANS ?
            <div className="mansi-keys noselect">
                {letters.map(letter => <div key={letter}
                    className="mansi-keys__key text text--big"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => pressHandler(letter)}>
                    {letter}
                </div>)}
            </div> : null}
    </>)
}