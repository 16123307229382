import CloseIcon from "../assets/icons/closeIcon";
import AutoComplite from "./autoComplite";

import {useState, useEffect} from 'react';
import {SHORT_MANS} from './../constants';
import SoundButton from "./soundButton";
import {setFirstSymbolCase} from './../features/help';
import {useSelector} from 'react-redux';

export default function TranslationInput({
    word,
    inputRef,
    autocomplite,
    setWord,
    changeHandler,
    translation,
    getTranslation,
    reset,
    choosedLang}) {

    const voice = useSelector(state => state.app.voice);
    const [selectedAutoWord, setSelectedAutoWord] = useState(null);

    useEffect(() => {
        setSelectedAutoWord(null)
    }, [word, autocomplite])

    useEffect(() => {
        const input = inputRef.current;
        const inputListener = () => {
            input.style.height = 0;
            input.style.height = input.scrollHeight + 15 + "px";
        }
        input.addEventListener('input', inputListener)
        return () => input.removeEventListener('input', inputListener)
    }, [inputRef, word])
    const handleKeysPress = (e) => {

        const containCheck = autocomplite.find(item => item.value.trim() === word.toLowerCase().trim())
        if (e.keyCode === 13 && selectedAutoWord !== null) {
            e.preventDefault();
            inputRef.current.blur();
            getTranslation(autocomplite[selectedAutoWord].id);
            setWord(setFirstSymbolCase(autocomplite[selectedAutoWord].value, word))
            return;
        }
        if (e.keyCode === 13 && containCheck) {
            e.preventDefault();
            inputRef.current.blur();
            getTranslation(containCheck.id);
            return;
        }
        if (e.keyCode === 13 && !containCheck && selectedAutoWord === null) {
            e.preventDefault();
            inputRef.current.blur();
            getTranslation();
            return;
        }
        if (e.keyCode === 40 && autocomplite.length) {
            if (selectedAutoWord === null) {setSelectedAutoWord(prev => 0); return };
            if (selectedAutoWord === autocomplite.length - 1) return
            else setSelectedAutoWord(prev => prev + 1)
        }
        if (e.keyCode === 38 && autocomplite.length) {
            if (selectedAutoWord === 0) {setSelectedAutoWord(prev => null); return }
            if (selectedAutoWord === null) return;
            else setSelectedAutoWord(prev => prev - 1);
        }
    }

    const renderSoundButton = () => {
        const soundsArr = translation?.sound;

        if (soundsArr && soundsArr.length > 0 && choosedLang === SHORT_MANS) {
            const rightSound = soundsArr.find(item => item.voice_type === voice);
            return <SoundButton audioFile={rightSound?.file} />
        }
        return null
    }


    return (
        <div className="input__wrap">
            <div className="input__input">
                <textarea
                    className="text text--mdl"
                    placeholder="Введите слово"
                    ref={inputRef}
                    type="text"
                    value={word}
                    onKeyDown={handleKeysPress}
                    onChange={(e) => changeHandler(e.target.value)}
                    onFocus={(e) => changeHandler(e.target.value)}
                />
                {!translation && <AutoComplite
                    words={autocomplite}
                    setWord={setWord}
                    getTranslation={getTranslation}
                    selectedAutoWord={selectedAutoWord}
                    searchWord={word}

                />}
            </div>

            <div className="input__buttons" >
                {renderSoundButton()}
                {word && <div className="input__button" onClick={reset} >
                    <CloseIcon />
                </div>}
            </div>
        </div>
    )
}